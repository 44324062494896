import * as React from 'react';
import Youtube from 'react-youtube';
import classNames from 'classnames';
import { CookiesLoadingIndicator } from '@devsisters/ui-common/lib/loading-indicator';

import * as styles from './styles.module.scss';
import SizeFixedElement, {
  FixedClasses,
  SizeFixedElementProps,
} from '../SizeFixedElement';
import useDisplayEffect from '../../hooks/useDisplayEffect';

type Props = ReactComponentProps<typeof Youtube> &
  Omit<SizeFixedElementProps, 'render'>;
const LazyYoutube: React.FC<Props> = ({ width, height, ...otherProps }) => {
  const [displayed, setDisplayed] = React.useState<0 | 1>(0);
  const render = React.useCallback(
    ({ visible, element }: FixedClasses) =>
      displayed && (
        <>
          <div className={styles.dimmer}>
            <CookiesLoadingIndicator style={{ fontSize: 110 }} />
          </div>
          <Youtube
            className={classNames(element, visible)}
            opts={{
              playerVars: { autoplay: 1 },
              width: '',
              height: '',
            }}
            {...otherProps}
          />
        </>
      ),
    [displayed]
  );
  const onEnter = React.useCallback(() => setDisplayed(1), []);
  const ref = useDisplayEffect({ onEnter });
  return (
    <SizeFixedElement
      innerRef={ref}
      width={width}
      height={height}
      render={render}
      {...otherProps}
    />
  );
};

export default React.memo(LazyYoutube);
