import * as React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import * as styles from './styles.module.scss';
import LazyImage from '../../components/LazyImage';
import LazyYoutube from '../../components/LazyYoutube';

const pageStyle = `
  body {
    background-color: #381e13;
    color: white;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  h2 {
    color: #f17300;
    letter-spacing: 1.1px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: -5px;
  }
  p {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 19px;
  }
  a {
    color: #ffcc7a;
  }
  ul {
    padding-left: 25px;
  }
  li {
    margin-bottom: 5px;
  }
`;

const PresskitPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <style>{pageStyle}</style>
        <link rel="canonical" href="https://www.cookierun.com/presskit" />
      </Helmet>
      <div className={styles.container}>
        <h1>CookieRun: OvenBreak</h1>
        <span>Press Kit</span>
        <LazyImage
          responsive
          src={require('../../images/presskit/presskit-title.jpg')}
          width={1600}
          height={832}
          alt="Game Title Image"
          className={classNames(styles.titleImage, styles.img)}
        />
        <h2>Factsheet</h2>
        <h3>Developer</h3>
        <p>
          <a href="http://www.devsisters.com/">Devsisters Corp</a> Based in
          Seoul, Sourth Korea
        </p>
        <h3>Release Date</h3>
        <p>October 27, 2016</p>
        <h3>Platforms</h3>
        <p>
          <a href="https://itunes.apple.com/app/cookie-run-ovenbreak/id963067330?mt=8">
            iOS
          </a>
          &nbsp;&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.devsisters.gb">
            Android
          </a>
        </p>
        <h3>Pricing Model</h3>
        <p>free (in-app purchases)</p>
        <h2>Description</h2>
        <p>Bake no prisoners!</p>
        <p>
          Help GingerBrave and his cookie friends break out of the Witch’s oven!
          Jump and Slide through the mysterious lands of the Cookie World in
          this endless runner!
        </p>
        <p>
          Choose from a wide selection of bite-sized characters, including a
          juice-loving vampire, a zombie with jam for brains, and even a
          doughnut from outer space! Think you can handle the heat? Then get
          your running shoes on and...
        </p>
        <p>Get Ready, Set, CookieRun!</p>
        <h2>Features</h2>
        <ul>
          <li>Jump and Slide to eat Jellies and avoid obstacles</li>
          <li>
            Earn chests to unlock and collect over 80 deliciously baked Cookies
            &amp; Pets
          </li>
          <li>
            Compete with players from around the world and beat their high score
          </li>
          <li>Challenge friends to an epic 1vs1 Race</li>
          <li>
            Collect Trophies to discover lands full of surprises and adventure
          </li>
          <li>Help and get help from friends for even more fun!</li>
        </ul>
        <h2>Video</h2>
        <LazyYoutube
          width={480}
          height={270}
          videoId="IwQyv-izgtA"
          responsive
        />
        <h2>Images</h2>
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_1_EN.jpg')}
          width={800}
          height={1280}
          alt="game image 0"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_2_EN.jpg')}
          width={800}
          height={1280}
          alt="game image 1"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_3_EN.jpg')}
          width={800}
          height={1280}
          alt="game image 2"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_4_EN.jpg')}
          width={800}
          height={1280}
          alt="game image 3"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_5_EN.jpg')}
          width={800}
          height={1280}
          alt="game image 4"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/android_6_EN.jpg')}
          width={1280}
          height={800}
          alt="game image 5"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/Gameplay_CherryBlossom.png')}
          width={2208}
          height={1242}
          alt="game play CherryBlossom cookie"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/Gameplay_DarkEnchantress.png')}
          width={2208}
          height={1242}
          alt="game play DarkEnchantress cookie"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/Gameplay_GingerBrave.png')}
          width={2208}
          height={1242}
          alt="game play GingerBrave cookie"
        />
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/Gameplay_IceCandy.png')}
          width={2208}
          height={1242}
          alt="game play IceCandy cookie"
        />
        <h2>Logo</h2>
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/logo.png')}
          width={538}
          height={199}
          alt="logo image"
        />
        <h2>Icon</h2>
        <LazyImage
          responsive
          className={styles.img}
          src={require('../../images/presskit/cro_app_icon.png')}
          width={1010}
          height={1010}
          alt="logo image"
        />
        <h2>About Devsisters</h2>
        <p>
          Devsisters has been developing mobile game apps since 2009. Our most
          successful games include the "OvenBreak" and "CookieRun" series, both
          of which ranked no. 1 free game in several countries. Solitaire:
          Decked Out was developed with an indie spirit by a small team within
          Devsisters.
        </p>
        <h2>Contact</h2>
        <h3>Press/Business Contact</h3>
        <p>
          <a href="mailto:press@devsisters.com">press@devsisters.com</a>
        </p>
        <h3>Facebook</h3>
        <p>
          <a href="https://facebook.com/CookieRun">facebook.com/CookieRun</a>
        </p>
        <h3>Forum</h3>
        <p>
          <a href="https://forum.cookierun.com">forum.cookierun.com</a>
        </p>
        <h3>Customer Support</h3>
        <p>
          <a href="https://cs.devsisters.com/cookierun-ovenbreak">
            cs.devsisters.com/cookierun-ovenbreak
          </a>
        </p>
        <div className={styles.footer}>
          <LazyImage
            className={styles.img}
            src={require('../../images/presskit/devsisters_ci.jpg')}
            width={198}
            height={26}
            alt="devsisters logo image"
          />
        </div>
      </div>
    </>
  );
};

export default PresskitPage;
