import * as React from 'react';
import { useInView } from 'react-intersection-observer';

interface Options {
  onEnter?: Function;
  onLeave?: Function;
}
const useDisplayEffect = ({ onEnter, onLeave }: Options) => {
  const [ref, inView] = useInView();
  React.useEffect(() => {
    if (inView && onEnter) {
      onEnter();
    }
    if (!inView && onLeave) {
      onLeave();
    }
  }, [inView, onEnter, onLeave]);
  return ref;
};

export default useDisplayEffect;
