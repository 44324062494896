import * as React from 'react';
import classNames from 'classnames';

import SizeFixedElement, {
  FixedClasses,
  SizeFixedElementProps,
} from './SizeFixedElement';
import useDisplayEffect from '../hooks/useDisplayEffect';

type Props = JSX.IntrinsicElements['div'] &
  Omit<SizeFixedElementProps, 'render'> & {
    src: string;
    alt?: string;
  };
const LazyImage: React.FC<Props> = ({
  width,
  height,
  src,
  alt,
  ...otherProps
}) => {
  const [displayed, setDisplayed] = React.useState(false);
  const render = React.useCallback(
    ({ visible, element }: FixedClasses) => (
      <img
        src={displayed ? src : void 0}
        className={classNames(element, displayed && visible)}
        alt={alt}
      />
    ),
    [displayed]
  );
  const onEnter = React.useCallback(() => setDisplayed(true), []);
  const ref = useDisplayEffect({ onEnter });
  return (
    <SizeFixedElement
      innerRef={ref}
      width={width}
      height={height}
      render={render}
      {...otherProps}
    />
  );
};

export default React.memo(LazyImage);
