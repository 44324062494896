import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const { element, visible } = styles;
const classes = { element, visible };

export interface FixedClasses {
  visible: string;
  element: string;
}

export interface SizeFixedElementProps {
  width: number;
  height: number;
  className?: string;
  render: (classes: FixedClasses) => React.ReactNode;
  responsive?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
}
const SizeFixedElement: React.FC<SizeFixedElementProps> = ({
  width,
  height,
  render,
  className,
  responsive,
  innerRef,
}) => {
  const ratio = (height / width) * 100;
  return (
    <div
      className={classNames(
        styles.container,
        responsive && styles.responsive,
        className
      )}
      style={responsive ? void 0 : { width, height }}
      ref={innerRef}
    >
      {responsive && <div style={{ paddingTop: `${ratio}%` }} />}
      {render(classes)}
    </div>
  );
};

export default React.memo(SizeFixedElement);
